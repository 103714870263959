import ApplicationContextProvider from '@stordata/cmdb-common/src/components/ApplicationContextProvider/index.jsx';
import { COLOR_MODE_KEY } from '@stordata/cmdb-common/src/services/useSaveColorModeToLocalStorage/index.js';

export default function ColorModeCompatibleApplicationContextProvider({ children }) {

  return (
    <ApplicationContextProvider colorMode={localStorage.getItem(COLOR_MODE_KEY) ?? undefined}>
      {children}
    </ApplicationContextProvider>
  );
}
