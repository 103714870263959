import { useEffect } from 'react';

import useApplicationContext from '../useApplicationContext/index.js';

export default function useApplyColorModeAttributeToBody() {
  const { theme } = useApplicationContext();

  useEffect(() => {
    document.body.dataset.colorMode = theme.palette.mode;
  }, [theme]);
}
