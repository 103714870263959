import useApplicationContext from '../useApplicationContext/index.js';

export default function useColorMode() {
  const { theme } = useApplicationContext();

  return {
    isDarkMode: theme.palette.mode === 'dark',
    textColor: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    secondaryBackgroundColor: theme.palette.mode === 'dark' ? '#383838' : 'white'
  };
}
