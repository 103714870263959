import { useEffect } from 'react';

import useApplicationContext from '../useApplicationContext/index.js';

export const COLOR_MODE_KEY = 'colorMode';

export default function useSaveColorModeToLocalStorage() {
  const { theme } = useApplicationContext();

  useEffect(() => {
    localStorage.setItem(COLOR_MODE_KEY, theme.palette.mode);
  }, [theme]);
}
