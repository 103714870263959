import StordataLogo from '@stordata/cmdb-common/src/components/StordataLogo/index.jsx';
import useIsDesktop from '@stordata/cmdb-common/src/services/useIsDesktop/index.js';

import styles from './Header.module.scss';

export default function Header({ isDarkMode }) {
  const isDesktop = useIsDesktop();

  return (
    <div className={styles.logo}>
      <StordataLogo large={isDesktop} isDarkMode={isDarkMode} />
    </div>
  );
}
